.submitted-message {
    transform: scale(-1, 1);
    position: absolute;
    z-index: 100000;
    color: rgba(255,255,255,.5);
    top: 140px;
    left: 20px;
    font-size: 22px;
    font-family: courier;
    /* border: 1px solid rgba(255,255,255,.6); */
    /* padding: .25em 1em; */
    animation: fadeOut 3s ease-out;
}


.message-line {
    width:101%;
    position: absolute;
    height: 2px;
    background-color: rgba(255,255,255,.5);
    top: 15px;
    animation: blink-freak 400ms infinite ease;
    transform: rotate(2deg);
    left: -10px;
}

.effect-line-2{
    top: 18px;
    animation: blink-freak 300ms infinite ease;
    transform: rotate(-2deg);
}
.effect-line-3{
    top: 5px;
    animation: blink-freak 400ms infinite ease;
    transform: rotate(-2deg);
}
.effect-line-4{
    top: 16px;
    animation: blink-freak 300ms infinite ease;
    transform: rotate(3deg);
}
.effect-line-5{
    top: 10px;
    animation: blink-freak 300ms infinite ease;
    transform: rotate(-1deg);
}

@keyframes fadeOut {
    0% {opacity: 1}
    100% {opacity: 0;}

}

@keyframes blink-freak {
    0% {
        opacity: 1;
        background-color: rgba(255,255,255,.5);

    }
    100% {
        opacity: 0;
        background-color: rgba(255,0,255,.5);
    }

}
