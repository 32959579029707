@import url("https://use.typekit.net/neh6qeb.css");

.arrow-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: .5em;
    /* max-width: 760px; */
    display: none;
    color: #F6D9D8;
    font-size: 1.3em;
}

.back-arrow, .next-arrow {
    cursor: pointer;
}

.problem-content {
    margin-top: 4em;
}

.back-large, .next-large {
    position:absolute;
    font-size: 2.5em;
    top: 50%;
    font-weight: 200;
    color: #F6D9D8;
    cursor: pointer;
    opacity: .7;
    transition: 500ms;
}

.back-large:hover, .next-large:hover {
    opacity: .4;
}

.back-large {
    left: 40px;
}

.next-large {
    right: 40px;
}

.scanning-hit-page {
    /* width: 860px; */
    /* margin: 0 auto; */
    /* font-family: 't26-carbon', sans-serif; */
    /* background: #408D8B; */
    color: white;
    /* background:url('../../assets/images/catacomb-2.png'); */
    background-size: contain;
    padding: 1em 0;
    min-height: 100vh;
}

.ab-scanning-hits {
    margin: 0;
    color: white;
}
.scanning-hit-wrapper {
  margin: 0;
  padding-left: 0;
  position: relative;
  top: -15px;
}
.scanning-hit-wrapper li {
  list-style: none;
}

.ab-scanning-hits a {
    color: white;
    font-size: 26px;
    opacity: .7;
    transition: 500ms;
}

.ab-scanning-hits a:hover {
    opacity: .4;
}

.scanning-hit-page .scanning-header {
    margin-top: 0;
}

@media only screen and (max-width:1190px) {
    .arrow-container {
        display: flex;
        position: relative;
        top: -10px;
    }

    .back-large, .next-large {
        display: none;
    }
}