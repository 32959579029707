.social-box {
  position: absolute;
  bottom: 10px;
  border: 1px solid white;
  border-radius: .2em;
  z-index: 1000;
  left: 50%;
  transform:translateX(-50%);
  list-style: none;
  margin: 0;
  border: 1px solid rgba(255,255,255,.6);
  height: 28px;
  animation: ease fadeIn 2s;
  padding-top: .25em;
}

.social-box a{
   color: rgba(255,255,255,.6);
   font-family: 'Audiowide', sans-serif;
}

.social-box li {
  display: inline;
  margin: 0 1em;
  cursor: pointer;
}

@keyframes fadeIn {
    0% {opacity: 0  }
    100% {opacity: 1}
}


@media only screen and (max-width:768px) {
  .social-box {
  bottom: 90px;
  }
}
