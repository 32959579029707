.contact-form {
    position:absolute;
    top: 65%;
    /* left: 50%; */
    right: -190px;
    transform:translate(-50%, -50%);
    z-index: 10001;
    animation: flyOut ease 2s;
}

textarea {
  outline: none;
}

.contact-form input, textarea {
    background: none;
    border: 1px rgba(255,255,255,.5) solid;
    margin: .5em;
    width: 375px;
    padding: .25em .5em;
    font-family: 'Gugi', sans-serif;
    outline: purple;
    focus: purple;
    caret-color: white;
    color: rgba(255,255,255,.4);
}

.contact-form label {
    display:block;
}

.contact-message textarea {
    height:    100px;
    padding: .25em .5em;
    color: rgba(255,255,255,.8);
    resize: none;
}

.contact-submit {
    background: none;
    color: rgba(255,255,255,.7);
    display: block;
    margin: 0 auto;
    width: 100%;
    width: 375px;
    font-family: 'Gugi', sans-serif;
    outline: none;
}

.contact-form input::placeholder {
    color: white;
}

.contact-form p {
    margin-top: .25em;
    color: rgba(255,255,255,.5);
    margin-left: 1em;
    font-family: 'Gugi'
}

.contact-form a {
    color: rgba(255,150, 150, .7);
    transition: 500ms;
}

.contact-form a:hover {
    color: rgba(155,250, 150, .7);
}

@keyframes flyOut {
    0% {right: -1000px  }
    100% {right: -190px}
}


@media only screen and (max-width: 600px) {
    .contact-form {
        /* max-width: 90%; */
        right: auto;
        left: 50%;
        /* top: 65%; */
        transform:translate(-50%, -50%);
    }

    .contact-form input, textarea, .contact-submit {
        width: 320px !important;
    }
}
