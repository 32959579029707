.scanning-hit-container {
  /* border: 1px solid black; */
  /* padding: 1em; */
  position: relative;
  border: 2px dotted white;
  padding: 1em;
  border-radius: .3em;
  /* font-family: 't26-carbon'; */
  background: rgba(255,255,255,.7);
  color: #333;
  font-size: 16px;
}

.scanning-hit-container p {
  font-size: 17px;
}

.scanning-hit-container .ui.card  {
  /* border: none;
  border-radius: 0; */
  /* box-shadow: none; */
  transition: initial;
}

.scanning-hit-container .ui.cards>.card {
  background: transparent;
}

.rating-groups {
  /* font-size: 1.8em; */
}



.scanning-hit-container .ui.card>.content>.header, .ui.cards>.card>.content>.header {
  /* font-family: 't26-carbon' !important; */
  font-size: 14px !important;
  /* color: white !important; */
}

.ui.card .scanning-hit-header {
  background-color: #333 !important;
  color: white;
}

.ui.card .scanning-hit-header a {
  color: lightblue;
}

.ui.card .scanning-hit-header a:hover {
  color: white;
}

.sh-title-wrapper {
  /* background: #222; */
  display: inline-block;
  position: relative;
  padding: 0;
  margin-bottom: .5rem;

}

.sh-title {
  font-weight: 700;
  font-size: 1.8em;
  margin-bottom: .25em;
  /* font-family: 'Spectral'; */
  letter-spacing: 1.2px;
  /* color: #F6D9D8; */
  padding:  0;
  line-height: 1.05em;
}

.sh-link a{
  /* color: pink; */
  opacity: .8;
  transition: 500ms;
}

.sh-link a:hover{
 opacity: .5;
 /* color: white; */
}

.next-scan {
  position: absolute;
  top: 1em;
  right: 10px;
}

.hit-meta-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rating-container .ratings-group {
  padding-top: .5em;
}




.scanning-hit-container .ui.card {
  width: 125px !important;
  font-size: 1.2em !important;
}

.scanning-hit-container .ui.card .content .header {
  color: #333 !important;
  font-size: 13px !important;
}

.scanning-hit-container .ui.card .content {
  text-align: center;
}

.scanning-hit-container .ui.card .label {
  margin-top: .25em;
}

.scanning-hit-container  .ratings-group {
  margin-top: .25em;
}

.sh-submission-date {
  font-style: italic
}

@media only screen and (max-width: 660px) {
  .scanning-hit-container {
      padding: .5em;
  }
}