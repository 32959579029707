.home-menu {
  position: absolute;
  z-index: 10000;
  color: white;
  bottom: 05px;
  right: 20px;
  font-size: 25px;
}


.home-menu-ul {
  list-style: none;
  margin: 0;
}


.home-menu-ul li {
  margin: 0;
  font-family: 'Audiowide';
  color: rgba(255,255,255,.5);
  cursor: pointer;
  font-size: .8em;
  transition: .5s;
}

.home-menu-ul li:active {
  color: rgba(255,255,255,.8);
}

.home-menu-ul li a{
  color: rgba(255,255,255,.5);
  text-decoration: none;
}
