@font-face {
  font-family: 'canterburyregular';
  src: url('../../assets/fonts/canterbury-webfont.eot');
  src: url('../../assets/fonts/canterbury-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../assets/fonts/canterbury-webfont.woff2') format('woff2'),
       url('../../assets/fonts/canterbury-webfont.woff') format('woff'),
       url('../../assets/fonts/canterbury-webfont.ttf') format('truetype'),
       url('../../assets/fonts/canterbury-webfont.svg#canterburyregular') format('svg');
  font-weight: normal;
  font-style: normal;
  text-decoration: underline;
}
.backgroundwhite {
background-color: rgb(244, 242, 242);
}

.wp-block-image img {
  width: 100%;
}

.art-header {
  color: hotpink;
}
/* 
.fw-header {
  background: url("../../assets/girl-robot.jpeg");
  min-height: 400px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.fw-header h1 {
 margin-top: 0;
} */




