.message-box {
    position: absolute;
    font-size: 22px;
    color: rgba(255,255,255,.6);
    top: 50%;
    left: 20px;
    z-index: 10000;
    font-family: courier;
    max-width: 90%;
}

.Cursor--blinking {
    animation: blink 1s infinite;
    position: relative;
    top: -3px;
}

@keyframes blink {
    0% {opacity: 1}
    100% {opacity: 0}
}

.event-ul {
    list-style: none;
    margin: 0
}
