@import url(https://fonts.googleapis.com/css2?family=Orbitron&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Libre+Barcode+39|Share+Tech+Mono);
@import url(https://fonts.googleapis.com/css?family=Audiowide|Gugi);
@import url(https://fonts.googleapis.com/css2?family=Orbitron&display=swap);
@import url(https://use.typekit.net/neh6qeb.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  font-size: 1.1em;
}

@font-face {
  font-family: 'Cormorant Garamond';
  src: url(/static/media/CormorantGaramond-Regular.e1f2c50e.ttf) format('truetype'); /* IE9 Compat Modes */
}

.App {
  min-width: 275px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* font-family: 'Libre Barcode 39', cursive;
font-family: 'Share Tech Mono', monospace; */

/* font-family: 'Audiowide', cursive;
font-family: 'Gugi', cursive; */


.home-page {
  position: absolute;
  height: 100vh;
  overflow: hidden;
  top: 0;
  width: 100vw;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
}

.home-content {
  position: relative;
  z-index: 3;
  /* width: 5px; */
  left: 20px;
  top: 80px;
  /* top: 300px; */
}


.home-overlay-2 {
    top: 0;
    left: 10;
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color:rgba(255,255,255,.7);
    z-index: 100;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* animation: backgroundShift 20s infinite alternate; */
}

.hp1 {
  background:rgba(255, 155,25, .5);
}

.hp2 {
  background: rgba(155, 055, 250, .5);
}

.hp3 {
  background: rgba(55, 55, 250, .5);
}

.home-panel a, .home-panel a:hover {
  color: rgba(255,255,255, .5)

}

.hp-panel-inner {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.home-panel h2 {
  position:absolute;
  top: 54%;
  font-weight: 300;
  color: rgba(255,255,255, .5);
}


.home-overlay {
  top: 0;
  left: 10;
  width: 100vw;
  height: 100vh;
  position: absolute;
  /* background-color:rgba(0,0,0,.5); */
  z-index: 99;
  animation: backgroundShift 20s infinite alternate;
 
}

@keyframes backgroundShift {
  0% {background-color: rgba(255,055,155,.4);}
   25%{background-color:rgba(125,0,145, .7);}
  50%{background-color:rgba(155,055,155,.4);}
  75%{background-color:rgba(0,255,0,.4);}
  100% {background-color: rgba(255, 155,25, .5);}
}

.hero-container {

}


.press-play {
  cursor: pointer;
}

.song-stuff {
    position: absolute;
    z-index: 1000;
    top: 15px;
    right: 15px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    color: rgba(255,255,255,.6);
    font-family: 'Audiowide', cursive;
    /* width: 350px; */

}

.sound-controls {
    float: left;
}

.sound-controls-ul {
    list-style: none;
    display: inline;
}

.sound-controls-ul li a{
  color: white;
  display: inline;
}

.sound-controls-ul li {
  display: inline;
}

.song-position {
    position: absolute;
    color: rgba(255,255,255,.4);
    bottom: 0;
    left: 25px;
    z-index: 1000;
    font-family: 'Libre Barcode 39', cursive;
    font-size: 2.4em;
}

.random-song {
  float: left;
  position: relative;
  cursor: pointer;
}

.control-button img {
    position: relative;
    width: 20px;
    top: 3px;
    margin-right: .5em;
}

.page-container {
  max-width: 1200px;
  margin: 0 auto;
}

.section-3 {
  height: 100vh;
}

.hp-i-w {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.hp-slide-wrapper {
  position:relative;
  left: -600px;
  width: 100%;
  height: 100%;
  animation: slideRight 0.5s forwards;
  animation-delay: 2s;
}

.home-panel .panel-overlay-link {
  opacity: 0;
  transition: .5s;
}

.home-panel .panel-overlay-link:hover {
  opacity: 1;
}

.panel-overlay-link {
  background: rgba(0,0,0,.5);
  height: 100%;
  width: 100%;
  display: block;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.panel-overlay-link .content {
  width: 200px;
  margin-bottom: 3em;
  color: rgba(255,255,255,.6);
}



@media only screen and (max-width: 900px) {
  .home-overlay-2 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .home-panel {
    position: relative;
  }
  .panel-overlay-link {
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;
  }

  .panel-overlay-link .content {
    margin-bottom: 1.5em;
  }
  

}

@media only screen and (max-width: 520px) {
    .song-stuff {
        left: 50%;
        right: 0;
        grid-template-columns: 1.2fr .8fr;
        transform: translateX(-50%);
        width: 350px;
    }
}



@keyframes slideRight {
  100% { left: 0; }
}
.glitch-text:nth-child(2) {
  color: white;
  animation: glitch2 2.5s infinite;
}

.glitch-text:nth-child(3) {
  color: yellow;
  animation: glitch3 2.5s infinite;
}

.glitch-wrapper {
    /* background: RGBA(117, 136, 190, .5); */
    height: 35px;
    width: 275px;
}



/*Keyframes*/

.home-content h1{
    text-transform: uppercase;
    /* font-family:'Share Tech Mono', monospace; */
    font-family: 'Audiowide', cursive;
    /* font-family: 'Gugi', cursive; */
    color: rgba(255,255,255,.7);
    font-size: 1.7em;
    /* font-size: 5em; */
   letter-spacing: 8px;
   margin: 0;
   line-height: .2;
   animation: glitch1 2.5s infinite;
   color: purple;
   width: 275px;
   background-color: RGBA(117, 136, 190, .30);
   position: relative;
   top: 10px;
}

@keyframes glitch1 {
  0% {
    transform: none;
    opacity: 1;
  }
  7% {
    transform: skew(-0.5deg, -0.9deg);
    opacity: 0.75;
  }
  10% {
    transform: none;
    opacity: 1;
  }
  27% {
    transform: none;
    opacity: 1;
  }
  30% {
    transform: skew(0.8deg, -0.1deg);
    opacity: 0.75;
  }
  35% {
    transform: none;
    opacity: 1;
  }
  52% {
    transform: none;
    opacity: 1;
  }
  55% {
    transform: skew(-1deg, 0.2deg);
    opacity: 0.75;
  }
  50% {
    transform: none;
    opacity: 1;
  }
  72% {
    transform: none;
    opacity: 1;
  }
  75% {
    transform: skew(0.4deg, 1deg);
    opacity: 0.75;
  }
  80% {
    transform: none;
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes glitch2 {
  0% {
    transform: none;
    opacity: 0.25;
  }
  7% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.25;
  }
  27% {
    transform: none;
    opacity: 0.25;
  }
  30% {
    transform: translate(-5px, -2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.25;
  }
  52% {
    transform: none;
    opacity: 0.25;
  }
  55% {
    transform: translate(-5px, -1px);
    opacity: 0.5;
  }
  50% {
    transform: none;
    opacity: 0.25;
  }
  72% {
    transform: none;
    opacity: 0.25;
  }
  75% {
    transform: translate(-2px, -6px);
    opacity: 0.5;
  }
  80% {
    transform: none;
    opacity: 0.25;
  }
  100% {
    transform: none;
    opacity: 0.25;
  }
}

@keyframes glitch3 {
  0% {
    transform: none;
    opacity: 0.25;
  }
  7% {
    transform: translate(2px, 3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.25;
  }
  27% {
    transform: none;
    opacity: 0.25;
  }
  30% {
    transform: translate(5px, 2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.25;
  }
  52% {
    transform: none;
    opacity: 0.25;
  }
  55% {
    transform: translate(5px, 1px);
    opacity: 0.5;
  }
  50% {
    transform: none;
    opacity: 0.25;
  }
  72% {
    transform: none;
    opacity: 0.25;
  }
  75% {
    transform: translate(2px, 6px);
    opacity: 0.5;
  }
  80% {
    transform: none;
    opacity: 0.25;
  }
  100% {
    transform: none;
    opacity: 0.25;
  }
}

.home-menu {
  position: absolute;
  z-index: 10000;
  color: white;
  bottom: 05px;
  right: 20px;
  font-size: 25px;
}


.home-menu-ul {
  list-style: none;
  margin: 0;
}


.home-menu-ul li {
  margin: 0;
  font-family: 'Audiowide';
  color: rgba(255,255,255,.5);
  cursor: pointer;
  font-size: .8em;
  transition: .5s;
}

.home-menu-ul li:active {
  color: rgba(255,255,255,.8);
}

.home-menu-ul li a{
  color: rgba(255,255,255,.5);
  text-decoration: none;
}

.social-box {
  position: absolute;
  bottom: 10px;
  border: 1px solid white;
  border-radius: .2em;
  z-index: 1000;
  left: 50%;
  transform:translateX(-50%);
  list-style: none;
  margin: 0;
  border: 1px solid rgba(255,255,255,.6);
  height: 28px;
  animation: ease fadeIn 2s;
  padding-top: .25em;
}

.social-box a{
   color: rgba(255,255,255,.6);
   font-family: 'Audiowide', sans-serif;
}

.social-box li {
  display: inline;
  margin: 0 1em;
  cursor: pointer;
}

@keyframes fadeIn {
    0% {opacity: 0  }
    100% {opacity: 1}
}


@media only screen and (max-width:768px) {
  .social-box {
  bottom: 90px;
  }
}

.contact-form {
    position:absolute;
    top: 65%;
    /* left: 50%; */
    right: -190px;
    transform:translate(-50%, -50%);
    z-index: 10001;
    animation: flyOut ease 2s;
}

textarea {
  outline: none;
}

.contact-form input, textarea {
    background: none;
    border: 1px rgba(255,255,255,.5) solid;
    margin: .5em;
    width: 375px;
    padding: .25em .5em;
    font-family: 'Gugi', sans-serif;
    outline: purple;
    focus: purple;
    caret-color: white;
    color: rgba(255,255,255,.4);
}

.contact-form label {
    display:block;
}

.contact-message textarea {
    height:    100px;
    padding: .25em .5em;
    color: rgba(255,255,255,.8);
    resize: none;
}

.contact-submit {
    background: none;
    color: rgba(255,255,255,.7);
    display: block;
    margin: 0 auto;
    width: 100%;
    width: 375px;
    font-family: 'Gugi', sans-serif;
    outline: none;
}

.contact-form input::-webkit-input-placeholder {
    color: white;
}

.contact-form input:-ms-input-placeholder {
    color: white;
}

.contact-form input::-ms-input-placeholder {
    color: white;
}

.contact-form input::placeholder {
    color: white;
}

.contact-form p {
    margin-top: .25em;
    color: rgba(255,255,255,.5);
    margin-left: 1em;
    font-family: 'Gugi'
}

.contact-form a {
    color: rgba(255,150, 150, .7);
    transition: 500ms;
}

.contact-form a:hover {
    color: rgba(155,250, 150, .7);
}

@keyframes flyOut {
    0% {right: -1000px  }
    100% {right: -190px}
}


@media only screen and (max-width: 600px) {
    .contact-form {
        /* max-width: 90%; */
        right: auto;
        left: 50%;
        /* top: 65%; */
        transform:translate(-50%, -50%);
    }

    .contact-form input, textarea, .contact-submit {
        width: 320px !important;
    }
}

.message-box {
    position: absolute;
    font-size: 22px;
    color: rgba(255,255,255,.6);
    top: 50%;
    left: 20px;
    z-index: 10000;
    font-family: courier;
    max-width: 90%;
}

.Cursor--blinking {
    animation: blink 1s infinite;
    position: relative;
    top: -3px;
}

@keyframes blink {
    0% {opacity: 1}
    100% {opacity: 0}
}

.event-ul {
    list-style: none;
    margin: 0
}


.submitted-message {
    transform: scale(-1, 1);
    position: absolute;
    z-index: 100000;
    color: rgba(255,255,255,.5);
    top: 140px;
    left: 20px;
    font-size: 22px;
    font-family: courier;
    /* border: 1px solid rgba(255,255,255,.6); */
    /* padding: .25em 1em; */
    animation: fadeOut 3s ease-out;
}


.message-line {
    width:101%;
    position: absolute;
    height: 2px;
    background-color: rgba(255,255,255,.5);
    top: 15px;
    animation: blink-freak 400ms infinite ease;
    transform: rotate(2deg);
    left: -10px;
}

.effect-line-2{
    top: 18px;
    animation: blink-freak 300ms infinite ease;
    transform: rotate(-2deg);
}
.effect-line-3{
    top: 5px;
    animation: blink-freak 400ms infinite ease;
    transform: rotate(-2deg);
}
.effect-line-4{
    top: 16px;
    animation: blink-freak 300ms infinite ease;
    transform: rotate(3deg);
}
.effect-line-5{
    top: 10px;
    animation: blink-freak 300ms infinite ease;
    transform: rotate(-1deg);
}

@keyframes fadeOut {
    0% {opacity: 1}
    100% {opacity: 0;}

}

@keyframes blink-freak {
    0% {
        opacity: 1;
        background-color: rgba(255,255,255,.5);

    }
    100% {
        opacity: 0;
        background-color: rgba(255,0,255,.5);
    }

}

@font-face {
  font-family: 'canterburyregular';
  src: url(/static/media/canterbury-webfont.6830f39d.eot);
  src: url(/static/media/canterbury-webfont.6830f39d.eot?#iefix) format('embedded-opentype'),
       url(/static/media/canterbury-webfont.a441a06b.woff2) format('woff2'),
       url(/static/media/canterbury-webfont.6d39940b.woff) format('woff'),
       url(/static/media/canterbury-webfont.8f34d6b4.ttf) format('truetype'),
       url(/static/media/canterbury-webfont.6db7cb25.svg#canterburyregular) format('svg');
  font-weight: normal;
  font-style: normal;
  text-decoration: underline;
}
.backgroundwhite {
background-color: rgb(244, 242, 242);
}

.wp-block-image img {
  width: 100%;
}

.art-header {
  color: hotpink;
}
/* 
.fw-header {
  background: url("../../assets/girl-robot.jpeg");
  min-height: 400px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.fw-header h1 {
 margin-top: 0;
} */





.arrow-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: .5em;
    /* max-width: 760px; */
    display: none;
    color: #F6D9D8;
    font-size: 1.3em;
}

.back-arrow, .next-arrow {
    cursor: pointer;
}

.problem-content {
    margin-top: 4em;
}

.back-large, .next-large {
    position:absolute;
    font-size: 2.5em;
    top: 50%;
    font-weight: 200;
    color: #F6D9D8;
    cursor: pointer;
    opacity: .7;
    transition: 500ms;
}

.back-large:hover, .next-large:hover {
    opacity: .4;
}

.back-large {
    left: 40px;
}

.next-large {
    right: 40px;
}

.scanning-hit-page {
    /* width: 860px; */
    /* margin: 0 auto; */
    /* font-family: 't26-carbon', sans-serif; */
    /* background: #408D8B; */
    color: white;
    /* background:url('../../assets/images/catacomb-2.png'); */
    background-size: contain;
    padding: 1em 0;
    min-height: 100vh;
}

.ab-scanning-hits {
    margin: 0;
    color: white;
}
.scanning-hit-wrapper {
  margin: 0;
  padding-left: 0;
  position: relative;
  top: -15px;
}
.scanning-hit-wrapper li {
  list-style: none;
}

.ab-scanning-hits a {
    color: white;
    font-size: 26px;
    opacity: .7;
    transition: 500ms;
}

.ab-scanning-hits a:hover {
    opacity: .4;
}

.scanning-hit-page .scanning-header {
    margin-top: 0;
}

@media only screen and (max-width:1190px) {
    .arrow-container {
        display: flex;
        position: relative;
        top: -10px;
    }

    .back-large, .next-large {
        display: none;
    }
}
.scanning-hit-container {
  /* border: 1px solid black; */
  /* padding: 1em; */
  position: relative;
  border: 2px dotted white;
  padding: 1em;
  border-radius: .3em;
  /* font-family: 't26-carbon'; */
  background: rgba(255,255,255,.7);
  color: #333;
  font-size: 16px;
}

.scanning-hit-container p {
  font-size: 17px;
}

.scanning-hit-container .ui.card  {
  /* border: none;
  border-radius: 0; */
  /* box-shadow: none; */
  transition: none 0s ease 0s;
  transition: initial;
}

.scanning-hit-container .ui.cards>.card {
  background: transparent;
}

.rating-groups {
  /* font-size: 1.8em; */
}



.scanning-hit-container .ui.card>.content>.header, .ui.cards>.card>.content>.header {
  /* font-family: 't26-carbon' !important; */
  font-size: 14px !important;
  /* color: white !important; */
}

.ui.card .scanning-hit-header {
  background-color: #333 !important;
  color: white;
}

.ui.card .scanning-hit-header a {
  color: lightblue;
}

.ui.card .scanning-hit-header a:hover {
  color: white;
}

.sh-title-wrapper {
  /* background: #222; */
  display: inline-block;
  position: relative;
  padding: 0;
  margin-bottom: .5rem;

}

.sh-title {
  font-weight: 700;
  font-size: 1.8em;
  margin-bottom: .25em;
  /* font-family: 'Spectral'; */
  letter-spacing: 1.2px;
  /* color: #F6D9D8; */
  padding:  0;
  line-height: 1.05em;
}

.sh-link a{
  /* color: pink; */
  opacity: .8;
  transition: 500ms;
}

.sh-link a:hover{
 opacity: .5;
 /* color: white; */
}

.next-scan {
  position: absolute;
  top: 1em;
  right: 10px;
}

.hit-meta-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rating-container .ratings-group {
  padding-top: .5em;
}




.scanning-hit-container .ui.card {
  width: 125px !important;
  font-size: 1.2em !important;
}

.scanning-hit-container .ui.card .content .header {
  color: #333 !important;
  font-size: 13px !important;
}

.scanning-hit-container .ui.card .content {
  text-align: center;
}

.scanning-hit-container .ui.card .label {
  margin-top: .25em;
}

.scanning-hit-container  .ratings-group {
  margin-top: .25em;
}

.sh-submission-date {
  font-style: italic
}

@media only screen and (max-width: 660px) {
  .scanning-hit-container {
      padding: .5em;
  }
}
