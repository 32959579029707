@import url('https://fonts.googleapis.com/css?family=Libre+Barcode+39|Share+Tech+Mono');
/* font-family: 'Libre Barcode 39', cursive;
font-family: 'Share Tech Mono', monospace; */
@import url('https://fonts.googleapis.com/css?family=Audiowide|Gugi');

@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

/* font-family: 'Audiowide', cursive;
font-family: 'Gugi', cursive; */


.home-page {
  position: absolute;
  height: 100vh;
  overflow: hidden;
  top: 0;
  width: 100vw;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
}

.home-content {
  position: relative;
  z-index: 3;
  /* width: 5px; */
  left: 20px;
  top: 80px;
  /* top: 300px; */
}


.home-overlay-2 {
    top: 0;
    left: 10;
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color:rgba(255,255,255,.7);
    z-index: 100;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* animation: backgroundShift 20s infinite alternate; */
}

.hp1 {
  background:rgba(255, 155,25, .5);
}

.hp2 {
  background: rgba(155, 055, 250, .5);
}

.hp3 {
  background: rgba(55, 55, 250, .5);
}

.home-panel a, .home-panel a:hover {
  color: rgba(255,255,255, .5)

}

.hp-panel-inner {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.home-panel h2 {
  position:absolute;
  top: 54%;
  font-weight: 300;
  color: rgba(255,255,255, .5);
}


.home-overlay {
  top: 0;
  left: 10;
  width: 100vw;
  height: 100vh;
  position: absolute;
  /* background-color:rgba(0,0,0,.5); */
  z-index: 99;
  animation: backgroundShift 20s infinite alternate;
 
}

@keyframes backgroundShift {
  0% {background-color: rgba(255,055,155,.4);}
   25%{background-color:rgba(125,0,145, .7);}
  50%{background-color:rgba(155,055,155,.4);}
  75%{background-color:rgba(0,255,0,.4);}
  100% {background-color: rgba(255, 155,25, .5);}
}

.hero-container {

}


.press-play {
  cursor: pointer;
}

.song-stuff {
    position: absolute;
    z-index: 1000;
    top: 15px;
    right: 15px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    color: rgba(255,255,255,.6);
    font-family: 'Audiowide', cursive;
    /* width: 350px; */

}

.sound-controls {
    float: left;
}

.sound-controls-ul {
    list-style: none;
    display: inline;
}

.sound-controls-ul li a{
  color: white;
  display: inline;
}

.sound-controls-ul li {
  display: inline;
}

.song-position {
    position: absolute;
    color: rgba(255,255,255,.4);
    bottom: 0;
    left: 25px;
    z-index: 1000;
    font-family: 'Libre Barcode 39', cursive;
    font-size: 2.4em;
}

.random-song {
  float: left;
  position: relative;
  cursor: pointer;
}

.control-button img {
    position: relative;
    width: 20px;
    top: 3px;
    margin-right: .5em;
}

.page-container {
  max-width: 1200px;
  margin: 0 auto;
}

.section-3 {
  height: 100vh;
}

.hp-i-w {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.hp-slide-wrapper {
  position:relative;
  left: -600px;
  width: 100%;
  height: 100%;
  animation: slideRight 0.5s forwards;
  animation-delay: 2s;
}

.home-panel .panel-overlay-link {
  opacity: 0;
  transition: .5s;
}

.home-panel .panel-overlay-link:hover {
  opacity: 1;
}

.panel-overlay-link {
  background: rgba(0,0,0,.5);
  height: 100%;
  width: 100%;
  display: block;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.panel-overlay-link .content {
  width: 200px;
  margin-bottom: 3em;
  color: rgba(255,255,255,.6);
}



@media only screen and (max-width: 900px) {
  .home-overlay-2 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .home-panel {
    position: relative;
  }
  .panel-overlay-link {
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;
  }

  .panel-overlay-link .content {
    margin-bottom: 1.5em;
  }
  

}

@media only screen and (max-width: 520px) {
    .song-stuff {
        left: 50%;
        right: 0;
        grid-template-columns: 1.2fr .8fr;
        transform: translateX(-50%);
        width: 350px;
    }
}



@keyframes slideRight {
  100% { left: 0; }
}